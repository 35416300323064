export default {
	"pages": {
		"root": {
			"name": "root",
			"children": [
				"65a374338e2e8e002176295e",
				"65a374338e2e8e0021762962"
			],
			"id": "root",
			"pageUrl": "root"
		},
		"65a374338e2e8e002176295e": {
			"id": "65a374338e2e8e002176295e",
			"name": "404",
			"pageUrl": "404"
		},
		"65a374338e2e8e0021762962": {
			"id": "65a374338e2e8e0021762962",
			"name": "index",
			"pageUrl": "index"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {
			"background": "--color-dark"
		},
		"seo": {
			"background_win10": "#232323",
			"title": " Ask Podcast & Video",
			"description": "Streamline your podcast and video consumption with AI.",
			"og:title": " Ask Podcast & Video",
			"og:description": "Streamline your podcast and video consumption with AI.",
			"favicon_apple_152px": "https://uploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06:05:27.801Z",
			"favicon_win10_270px": "https://uploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06:05:27.801Z",
			"og:image": "https://uploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06:05:27.801Z",
			"favicon_32px": "https://uploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06:05:27.801Z",
			"robotsFileType": "autogenerated"
		}
	}
}
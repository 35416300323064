import React from "react";
import theme from "theme";
import { Theme, Link, Image, LinkBox, Box, Section, Text, Span, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FiMenu } from "react-icons/fi";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				 Ask Podcast & Video
			</title>
			<meta name={"description"} content={"Streamline your podcast and video consumption with AI."} />
			<meta property={"og:title"} content={" Ask Podcast & Video"} />
			<meta property={"og:description"} content={"Streamline your podcast and video consumption with AI."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06:05:27.801Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06:05:27.801Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06:05:27.801Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06:05:27.801Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06:05:27.801Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06:05:27.801Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06:05:27.801Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Section quarkly-title="Header" padding="44px 0 44px 0" border-color="rgba(0, 0, 0, 0.94)">
			<Override slot="SectionContent" flex-direction="row" justify-content="space-between" />
			<LinkBox align-items="flex-start">
				<Image
					src="https://uploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06:05:27.801Z"
					width="92px"
					height="auto"
					lg-max-width="150px"
					srcSet="https://smartuploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06%3A05%3A27.801Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06%3A05%3A27.801Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06%3A05%3A27.801Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06%3A05%3A27.801Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06%3A05%3A27.801Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06%3A05%3A27.801Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06%3A05%3A27.801Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</LinkBox>
			<Components.QuarklycommunityKitMobileSidePanel>
				<Override slot="Content" background="rgba(255, 255, 255, 0)" padding="0px 0px 0px 0px" md-background="--color-dark" />
				<Override slot="Button Icon" md-color="--green" />
				<Override slot="Button Text" md-display="none" />
				<Override slot="Button Icon :closed" category="fi" icon={FiMenu} />
				<Override slot="Cross" md-color="--light" />
				<Box
					md-justify-content="center"
					md-padding="30px 0px 30px 0px"
					display="flex"
					justify-content="flex-end"
					md-align-items="center"
					md-flex-direction="column"
				>
					<Link
						padding="6px 0px 6px 0px"
						margin="0px 0px 0px 16px"
						hover-color="--green"
						transition="color 0.3s --transitionTimingFunction-easeInOut 0s"
						hover-border-color="--color-green"
						border-width="1px 0px 0px 0px"
						href="#"
						color="--light"
						border-color="rgba(255, 255, 255, 0)"
						border-style="solid"
						lg-margin="0px 0px 0px 0px"
						text-decoration-line="initial"
						font="500 24px/1.5 --fontFamily-googleMontserrat"
					>
						Lingvl.com
					</Link>
				</Box>
			</Components.QuarklycommunityKitMobileSidePanel>
		</Section>
		<Section padding="36px 0 20px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" border-color="rgba(0, 0, 0, 0.94)">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="60%"
				md-width="100%"
			>
				<Text
					font="normal 700 80px/1.2 --fontFamily-googleMontserrat"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
					padding="0px 0px 40px 0px"
				>
					Ask Podcast & Video
				</Text>
				<Text
					margin="0px 0px 16px 0px"
					color="--light"
					font="normal 500 50px/1.2 --fontFamily-googleMontserrat"
					lg-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 22px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					width="1200px"
					padding="0px 0px 80px 0px"
				>
					Streamline your podcast and video consumption with AI
				</Text>
			</Box>
		</Section>
		<Section padding="60px 0 40px 0" lg-padding="50px 0 25px 0" md-padding="30px 0 25px 0" border-color="rgba(0, 0, 0, 0.94)">
			<Override slot="SectionContent" align-self="flex-end" padding="0px 0px 40px 0px" />
			<Text
				color="--light"
				font="normal 500 32px/1.2 --fontFamily-googleMontserrat"
				lg-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
				width="100%"
				margin="0px 0px 0px 0px"
				padding="0px 0px 40px 0px"
			>
				🎧 🔗
				<br />
				Send links of podcast or video for a transcript-based summary.{" "}
				<Span
					color="--green"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Unlimited queries.
				</Span>
			</Text>
			<Box
				empty-min-height="64px"
				empty-border-color="LightGray"
				width="50%"
				empty-min-width="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				margin="40px 0px 0px 0px"
				lg-width="70%"
				md-width="100%"
				padding="0px 0px 20px 0px"
			>
				<Box display="grid" grid-template-columns="1fr 1fr" grid-gap="0px 40px">
					<Box padding="0px 0px 20px 0px">
						<Image
							src="https://images.unsplash.com/photo-1635792026715-0417ccb02f38?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
							max-height="120px"
							width="100%"
							object-fit="cover"
							lg-height="120px"
							srcSet="https://images.unsplash.com/photo-1635792026715-0417ccb02f38?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1635792026715-0417ccb02f38?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1635792026715-0417ccb02f38?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1635792026715-0417ccb02f38?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1635792026715-0417ccb02f38?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1635792026715-0417ccb02f38?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1635792026715-0417ccb02f38?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text color="--green" lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif" margin="8px 0px 0px 0px" font="--lead">
							Transcribe
						</Text>
					</Box>
					<Box padding="0px 0px 20px 0px">
						<Image
							src="https://images.unsplash.com/photo-1634320714682-ae8b9c9cee60?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
							max-height="120px"
							width="100%"
							object-fit="cover"
							lg-height="120px"
							srcSet="https://images.unsplash.com/photo-1634320714682-ae8b9c9cee60?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1634320714682-ae8b9c9cee60?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1634320714682-ae8b9c9cee60?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1634320714682-ae8b9c9cee60?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1634320714682-ae8b9c9cee60?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1634320714682-ae8b9c9cee60?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1634320714682-ae8b9c9cee60?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text color="--green" lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif" margin="8px 0px 0px 0px" font="--lead">
							Summarized
						</Text>
					</Box>
					<Box>
						<Image
							lg-height="120px"
							src="https://images.unsplash.com/photo-1557318041-1ce374d55ebf?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
							max-height="120px"
							width="100%"
							object-fit="cover"
							srcSet="https://images.unsplash.com/photo-1557318041-1ce374d55ebf?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1557318041-1ce374d55ebf?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1557318041-1ce374d55ebf?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1557318041-1ce374d55ebf?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1557318041-1ce374d55ebf?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1557318041-1ce374d55ebf?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1557318041-1ce374d55ebf?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text margin="8px 0px 0px 0px" font="--lead" color="--green" lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif">
							Query
						</Text>
					</Box>
					<Box>
						<Image
							lg-height="120px"
							src="https://images.unsplash.com/photo-1705077111154-94a4a210461e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
							max-height="120px"
							width="100%"
							object-fit="cover"
							srcSet="https://images.unsplash.com/photo-1705077111154-94a4a210461e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1705077111154-94a4a210461e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1705077111154-94a4a210461e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1705077111154-94a4a210461e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1705077111154-94a4a210461e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1705077111154-94a4a210461e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1705077111154-94a4a210461e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text margin="8px 0px 0px 0px" font="--lead" color="--green" lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif">
							Analyze
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="60px 0 4px 0" border-color="rgba(0, 0, 0, 0.94)">
			<Box
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
			>
				<Text
					margin="0px 0px 40px 0px"
					color="--light"
					font="normal 500 50px/1.2 --fontFamily-googleMontserrat"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 16px 0px"
					width="800px"
				>
					Structured Summary and{" "}
					<br />
					Dynamic Note-taking Formats for Podcast and Video
				</Text>
			</Box>
		</Section>
		<Section padding="px 0 60px 0" md-padding="30px 0 30px 0" border-color="rgba(0, 0, 0, 0.99)">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" order="0" />
			<Box
				flex-direction="column"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				display="flex"
				justify-content="flex-start"
				md-width="100%"
				flex="1 1 0%"
			>
				<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-13">
					<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
					<Box
						padding="16px 16px 16px 16px"
						lg-order="-2"
						lg-width="100%"
						width="100%"
						margin="0px 0px 0px 0px"
						sm-padding="0px 0px 0px 0px"
						sm-margin="0px 0px 30px 0px"
						flex-direction="column"
						display="flex"
					>
						<Box
							overflow-x="hidden"
							position="relative"
							hover-transform="translateY(-10px)"
							width="100%"
							height="1200px"
							overflow-y="hidden"
							transform="translateY(0px)"
							transition="transform 0.2s ease-in-out 0s"
							padding="0px 0px 70% 0px"
						>
							<Image
								position="absolute"
								display="block"
								width="100%"
								left="auto"
								bottom="-1029px"
								min-height="100%"
								src="https://uploads.quarkly.io/65a374338e2e8e0021762940/images/ChatGPT-Ask-Pocast-Video.png?v=2024-01-14T08:57:38.504Z"
								object-fit="cover"
								top="-39px"
								right="-10px"
								srcSet="https://smartuploads.quarkly.io/65a374338e2e8e0021762940/images/ChatGPT-Ask-Pocast-Video.png?v=2024-01-14T08%3A57%3A38.504Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65a374338e2e8e0021762940/images/ChatGPT-Ask-Pocast-Video.png?v=2024-01-14T08%3A57%3A38.504Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65a374338e2e8e0021762940/images/ChatGPT-Ask-Pocast-Video.png?v=2024-01-14T08%3A57%3A38.504Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65a374338e2e8e0021762940/images/ChatGPT-Ask-Pocast-Video.png?v=2024-01-14T08%3A57%3A38.504Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65a374338e2e8e0021762940/images/ChatGPT-Ask-Pocast-Video.png?v=2024-01-14T08%3A57%3A38.504Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65a374338e2e8e0021762940/images/ChatGPT-Ask-Pocast-Video.png?v=2024-01-14T08%3A57%3A38.504Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65a374338e2e8e0021762940/images/ChatGPT-Ask-Pocast-Video.png?v=2024-01-14T08%3A57%3A38.504Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
						</Box>
					</Box>
				</Section>
			</Box>
		</Section>
		<Section padding="60px 0 4px 0" border-color="rgba(0, 0, 0, 0.94)">
			<Box
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				height="200px"
			>
				<Text
					margin="0px 0px 40px 0px"
					color="--light"
					font="normal 500 50px/1.2 --fontFamily-googleMontserrat"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 16px 0px"
					width="800px"
				>
					Unveiled Rich and Smooth Experience in ChatGPT Version
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" md-padding="20px 0 12px 0" border-color="rgba(0, 0, 0, 0.94)">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Text
					sm-margin="24px 0px 16px 0px"
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
				>
					Support for Diverse Platforms{" "}
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 32px 0px"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Videos
					</Strong>
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Podcasts
					</Strong>
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Zoom records
					</Strong>
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Text
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					sm-margin="24px 0px 16px 0px"
				>
					Precised in-context query
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					text-align="left"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						In-Depth AI Insights:
					</Strong>
					{" "}Explore your video content deeply, with valuable perspectives unlocked through direct transcript interactions.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Text
					margin="32px 0px 16px 0px"
					font="--lead"
					color="--green"
					lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="12px 0px 16px 0px"
					sm-margin="24px 0px 16px 0px"
				>
					Get Transcription (Coming soon)
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--light"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					text-align="left"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Comprehensive Transcripts:
					</Strong>
					{" "}Access detailed, full-length transcriptions.
				</Text>
			</Box>
		</Section>
		<Section padding="60px 0 4px 0" border-color="rgba(0, 0, 0, 0.94)">
			<Box
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				height="200px"
			>
				<Text
					margin="0px 0px 40px 0px"
					color="--light"
					font="normal 500 50px/1.2 --fontFamily-googleMontserrat"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 16px 0px"
					width="800px"
				>
					Choose your{" "}
					<br />
					simple pricing plan
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="100%"
				display="flex"
				padding="48px 40px 56px 40px"
				background="--color-light"
				border-radius="24px"
				align-items="center"
				justify-content="flex-start"
				flex-direction="column"
				border-width="2px"
				border-style="solid"
				border-color="--color-green"
				lg-padding="48px 35px 56px 35px"
				position="relative"
			>
				<Text
					padding="1px 26px 5px 24px"
					color="#04080C"
					background="--color-green"
					font="--base"
					position="absolute"
					transform="rotate(90deg)"
					right="-38px"
					left="auto"
					top="70px"
					bottom="auto"
					border-radius="0px 0px 12px 12px"
				>
					Popular
				</Text>
				<Text margin="0px 0px 24px 0px" font="--headline3">
					Free trial
				</Text>
				<Text margin="0px 0px 8px 0px" font="--headline1">
					$0
				</Text>
				<Text margin="0px 0px 32px 0px" color="--greyD2" text-align="center" font="--base">
					per month
				</Text>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD2"
					text-align="center"
					font="--base"
					flex="1 1 auto"
				>
					Unlimited access
					<br />
					{" "}Max length 3-4 hours
				</Text>
				<Link
					text-decoration-line="initial"
					color="--dark"
					font="--lead"
					padding="12px 24px 12px 24px"
					border-radius="8px"
					background="--color-green"
					lg-padding="12px 20px 12px 20px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="#c0e017"
					target="_blank"
					href="https://chat.openai.com/g/g-J4wRBF6Jd-ask-pocast-video"
				>
					Try Ask Podcast & Video GPT
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 160px 0" md-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="60%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					src="https://uploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06:05:27.801Z"
					width="192px"
					height="auto"
					lg-max-width="150px"
					srcSet="https://smartuploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06%3A05%3A27.801Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06%3A05%3A27.801Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06%3A05%3A27.801Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06%3A05%3A27.801Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06%3A05%3A27.801Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06%3A05%3A27.801Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65a374338e2e8e0021762940/images/2.png?v=2024-01-14T06%3A05%3A27.801Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="40%"
				md-width="100%"
				md-margin="24px 0px 0px 0px"
				empty-min-width="64px"
			>
				<Text
					md-text-align="left"
					margin="0px 0px 26px 0px"
					color="--light"
					font="--headline4"
					text-align="right"
					lg-font="700 22px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
				>
					Let’s stay in touch with Lingvl
				</Text>
			</Box>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});